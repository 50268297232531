import React from 'react'

import Layout from '../components/layout'

import picAaron from "../images/staff/Aaron.jpg";
import picAlex from "../images/staff/Alex.jpg";
import picAngie from "../images/staff/Angie.jpg";
import picNathan from "../images/staff/Nathan.jpg";
import picTim from "../images/staff/Tim.jpg";
import picTyler from "../images/staff/Tyler.jpg";

const TeamPage = () => (
  <Layout bgClass="team">

  <div className="textPage">
  <h2 className="major">The Team</h2>
  <div className="textPageDetails">
    <p>
    Nakatomi Print Labs began as an extension of <a href="http://www.nakatomiinc.com/">NakatomiInc.com</a>, the artist collective based out of Austin, TX. What started in 2009 as a simple garage screenprinting studio has grown into a full-fledged printshop, serving clients all over the world and printing everything from fine art prints to worldwide gigposters. Nakatomi Print Labs has the distinction of being run, staffed, and utilized by artists and is committed to delivering the best quality screen printed poster possible.
    </p>
  </div>

  <div id="team">
    <div class="member">
    <div class="photo">
             <a href="http://www.timdoyle.com/" target="nakaout"><img alt="Tim Doyle" src={picTim} /></a>
          </div>
    <div class="name">
             Tim Doyle
          </div>
    <div class="title">
             Owner / Founder
          </div>
    <div class="location">
             Austin, TX
          </div>
    <div class="location">
             <a href="http://www.timdoyle.com/" target="nakaout">timdoyle.com</a>
          </div>
    <div class="location">
             <a href="http://www.instagram.com/TimothyPDoyle" target="nakaout">@TimothyPDoyle</a>
          </div>
    <div class="details">
             Founder and principal artist of Nakatomi Inc. A Texas-based illustrator who has produced art for IDW, Marvel, Metallica, as well as several sold-out solo art shows at SpokeArt. Lives in Austin with his wife, two children, Berni Wrightson's Corgi, and a very bad cat.
          </div>
    <p></p></div>
    <div class="member">
    <div class="photo">
             <a href="http://www.hokumpress.com/" target="nakaout"><img alt="Tyler Skaggs" src={picTyler} /></a>
          </div>
    <div class="name">
             Tyler Skaggs
          </div>
    <div class="title">
             Shop Manager
          </div>
    <div class="location">
             Las Cruces, NM
          </div>
    <div class="location">
             <a href="http://www.hokumpress.com/" target="nakaout">hokumpress.com</a>
          </div>
    <div class="location">
             <a href="http://www.instagram.com/HokumPress" target="nakaout">@HokumPress</a>
          </div>
    <div class="details">
             Illustrator and screen printer born in Las Cruses, NM. Studied Fine Arts with an emphasis in Painting at New Mexico State University and has been screen printing professionally since 2005. Relocated to Austin in 2011 where he now lives with his wife and three little doggies.
          </div>
    <p></p></div>
    <div class="member">
    <div class="photo">
             <img alt="Alex Baber" src={picAlex} />
          </div>
    <div class="name">
             Alex Baber
          </div>
    <div class="title">
             Shipping/Customer Service
          </div>
    <div class="location">
             Willimantic, CT
          </div>
    <div class="location">
             &nbsp;
          </div>
    <div class="location">
             &nbsp;
          </div>
    <div class="details">
             Moved to Austin in 2010. Has seen all seven <i>Police Academy</i> movies.
          </div>
    <p></p></div>
    <div class="member">
    <div class="photo">
             <a href="http://www.aarondegruyter.com/" target="nakaout"><img alt="Aaron Degruyter" src={picAaron} /></a>
          </div>
    <div class="name">
             Aaron Degruyter
          </div>
    <div class="title">
             Printer
          </div>
    <div class="location">
             Las Cruces, NM
          </div>
    <div class="location">
             <a href="http://www.aarondegruyter.com/" target="nakaout">aarondegruyter.com</a>
          </div>
    <div class="location">
             <a href="http://www.instagram.com/AaronDegruyter" target="nakaout">@AaronDegruyter</a>
          </div>
    <div class="details">
             Illustrator and printmaker with a Bachelor’s Degree in Fine Arts from New Mexico State University. A Las Cruces native who has been screen printing for over 5 years, and recent Austin transplant.
          </div>
    <p></p></div>
    <div class="member">
    <div class="photo">
             <a href="http://www.nathanbeach.com/" target="nakaout"><img alt="Nathan Beach" src={picNathan} /></a>
          </div>
    <div class="name">
             Nathan Beach
          </div>
    <div class="title">
             Chief Technology Officer
          </div>
    <div class="location">
             Richardson, TX
          </div>
    <div class="location">
             <a href="http://www.nathanbeach.com/" target="nakaout">nathanbeach.com</a>
          </div>
    <div class="location">
             <a href="http://www.instagram.com/postcaveart" target="nakaout">@postcaveart</a>
          </div>
    <div class="details">
             Keeps the web services running. Born in Dallas, raised in Richardson, undergraduate in German Language at UT Austin, masters in IT Management at UT Dallas.  Printmaker and painter.  Member of the band <a href="http://www.climateincorporated.com/">Climate</a>.  Currently lives in North Texas with his wife, twin toddlers, and some other baby. Oh, and another one after that. Oh, and the twins are eight now. Maybe I should update this?
          </div>
    <p></p></div>
    <div class="member">
    <div class="photo">
             <img alt="Angie Genesi" src={picAngie} />
          </div>
    <div class="name">
             Angie Genesi
          </div>
    <div class="title">
             Co-owner / Recluse
          </div>
    <div class="location">
             Austin, TX
          </div>
    <div class="location">
             &nbsp;
          </div>
    <div class="location">
             &nbsp;
          </div>
    <div class="details">
             Moved to Austin in 2002. Bachelor of Fine arts from Indiana University of Pennsylvania. Owner of cats.
          </div>
    <p></p></div>
    </div>
    </div>
  </Layout>
)

export default TeamPage
